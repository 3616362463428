import { Box, Typography } from '@material-ui/core'
import Logo from 'components/Logo'
import React from 'react'
import { useStyles } from './styles'

const AuthSideBar = () => {
  const s = useStyles({})
  return (
    <Box className={s.wrapper}>
      <Logo />
      <Box className={s.textContainer}>
        <Typography className={s.text}>Connecting The Future Of MCA</Typography>
        <Typography className={s.smallText}>
          We are an automated platform that streamlines the merchant cash advance process, enable ISOs and lenders to manage their businesses from one
          centralized convenient place. We match merchant applications to the optimal lender.
        </Typography>
      </Box>
    </Box>
  )
}

export default AuthSideBar
