import { API_GET_VENDOR_LIST, API_GET_VENDOR_BY_ID, API_CREATE_VENDOR, API_EDIT_VENDOR, API_GET_VENDOR_LIGHT_LIST } from 'constants/apiUrl'
import { privateInstance } from 'services/axios/privateInstance'
import { AxiosResponse } from 'axios'
import { IPagination } from 'typescript/interfaces/pagination'
import { SortVendorFields, IVendorListItem, IVendorEntity, IVendorInitValues, IVendorListLightItem } from 'typescript/interfaces/vendor'

export const getVendorsList = (params: IPagination<SortVendorFields>) =>
  privateInstance.get<IPagination<SortVendorFields>, AxiosResponse<Array<IVendorListItem>>>(API_GET_VENDOR_LIST, {
    params: params,
  })

export const getVendorById = (id: string) =>
  privateInstance.get<{ id: string }, AxiosResponse<IVendorEntity>>(API_GET_VENDOR_BY_ID.replace(':id', id))

export const createVendor = (values: IVendorInitValues) =>
  privateInstance.post<IVendorInitValues, AxiosResponse<IVendorEntity>>(API_CREATE_VENDOR, values)

export const editVendor = (values: IVendorInitValues & { id: string | number }) =>
  privateInstance.put<IVendorInitValues, AxiosResponse<IVendorEntity>>(API_EDIT_VENDOR, values)

export const getVendorLightList = (params: IPagination<SortVendorFields>) =>
  privateInstance.get<IPagination<SortVendorFields>, AxiosResponse<Array<IVendorListLightItem>>>(API_GET_VENDOR_LIGHT_LIST, {
    params: params,
  })
