import React, { FC } from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import { VENDOR_ADD_NEW_URL, VENDOR_INFO_URL, VENDOR_LIST_URL, VENDOR_EDIT_URL, VENDOR_OWN_URL } from 'constants/routes'
import VendorListPage from 'pages/VendorList'
import CreateVendorPage from 'pages/NewVendor'
import VendorInfoPage from 'pages/VendorInfo'
import EditVendorPage from 'pages/VendorEdit'
import { usePermission } from 'hooks/usePermission'
import { EDIT_VENDOR_PERM, VENDOR_OWN_VIEW_PERM, VENDORS_ADD_PERM, VENDORS_MODULE_PERM } from 'constants/permissions'

const VendorsContainer: FC = () => {
  const { hasPermission } = usePermission()
  return (
    <Switch>
      {hasPermission(VENDORS_MODULE_PERM) && <Route path={VENDOR_LIST_URL} component={VendorListPage} />}
      {hasPermission(VENDORS_ADD_PERM) && <Route path={VENDOR_ADD_NEW_URL} component={CreateVendorPage} />}
      {(hasPermission(VENDORS_MODULE_PERM) || hasPermission(VENDOR_OWN_VIEW_PERM)) && (
        <Route path={[VENDOR_INFO_URL, VENDOR_OWN_URL]} component={VendorInfoPage} />
      )}
      {hasPermission(EDIT_VENDOR_PERM) && <Route path={VENDOR_EDIT_URL} component={EditVendorPage} />}
      {hasPermission(VENDORS_MODULE_PERM) && <Redirect to={VENDOR_LIST_URL} />}
    </Switch>
  )
}

export default VendorsContainer
