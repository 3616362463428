import { makeStyles, Typography } from '@material-ui/core'
import React from 'react'

const useStyles = makeStyles(() => ({
  logo: {
    '& span': {
      fontSize: 40,
    },
  },
  funders: {
    fontWeight: 300,
    color: '#fff',
  },
  link: {
    fontWeight: 700,
    color: '#5CAA5B',
  },
}))

const Logo = () => {
  const s = useStyles()
  return (
    <Typography className={s.logo} component="p">
      <Typography component="span" className={s.funders}>
        Funders
      </Typography>
      <Typography component="span" className={s.link}>
        Link
      </Typography>
    </Typography>
  )
}

export default Logo
