import React, { FC } from 'react'
import { Box } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import { ReactComponent as ArrowLeftIcon } from 'assets/svg/ArrowLeftIcon.svg'
import IconButton from '@material-ui/core/IconButton'
import Button from 'UI/Button/ButtonWithPreloader'
import { generatePath, useHistory, useParams } from 'react-router-dom'
import CircularProgress from '@material-ui/core/CircularProgress/CircularProgress'
import { useQuery } from 'react-query'
import { getVendorById } from 'services/vendor'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import { TRANSACTIONS_URL, VENDOR_EDIT_URL, WITHDRAWAL_URL } from 'constants/routes'
import { ReactComponent as EditPenIcon } from 'assets/svg/EditPenIcon.svg'
import BusinessInfo from 'components/CompanyInfo/BusinessInfo'
import { convertEmptyField } from 'utils/formatters'
import { usePermission } from 'hooks/usePermission'
import { EDIT_VENDOR_PERM, TRANSACTION_PERM, WITHDRAWAL_PERM } from 'constants/permissions'
import UserPageInfo from 'components/UserPageInfo'
import { useAdditionalMaterialStyle } from 'containers/MaterialUiContainer/additionalStyles'

const InfoVendorContainer: FC = () => {
  const { goBack, push } = useHistory()
  const { hasPermission, isOwnVendor } = usePermission()
  const aStyle = useAdditionalMaterialStyle()

  const { id } = useParams<{ id: string }>()
  const { data, isLoading } = useQuery(['vendorById', id], () => getVendorById(id), {
    retry: false,
    cacheTime: 0,
  })
  return (
    <Box className={aStyle.wrapper}>
      {isLoading || !data ? (
        <Box display="flex" justifyContent="center">
          <CircularProgress color="primary" />
        </Box>
      ) : (
        <>
          <Box display="flex" alignItems="center">
            <Box ml="3rem" mr="1.5rem">
              <IconButton color="inherit" onClick={() => goBack()}>
                <ArrowLeftIcon />
              </IconButton>
            </Box>
            <Typography color="textSecondary" variant="h1">
              {data.data.businessName}
            </Typography>
            <Box mr="3rem" flex="1" display="flex" justifyContent="flex-end">
              {(hasPermission(TRANSACTION_PERM) || isOwnVendor(data?.data.id)) && (
                <Button
                  onClick={() =>
                    push(
                      generatePath(TRANSACTIONS_URL, {
                        id: data.data.id,
                        type: 'vendor',
                        businessName: data.data.businessName,
                      }),
                    )
                  }
                  variant="outlined"
                  color="secondary"
                >
                  Activity
                </Button>
              )}
              {(hasPermission(WITHDRAWAL_PERM) || isOwnVendor(data?.data.id)) && (
                <Box ml="1rem">
                  <Button
                    variant="outlined"
                    color="secondary"
                    onClick={() =>
                      push(
                        generatePath(WITHDRAWAL_URL, {
                          id: data.data.id,
                          type: 'vendor',
                          businessName: data.data.businessName,
                        }),
                      )
                    }
                  >
                    Withdrawals
                  </Button>
                </Box>
              )}
            </Box>
          </Box>
          <Box mt="2rem">
            <Grid container spacing={4}>
              <Grid item xs={6}>
                <Paper elevation={0}>
                  <Box width="100%" display="flex" justifyContent="flex-end" p="1rem" position="relative">
                    {hasPermission(EDIT_VENDOR_PERM) && (
                      <IconButton
                        className={aStyle.absoluteEditButton}
                        size="small"
                        onClick={() =>
                          push(
                            generatePath(VENDOR_EDIT_URL, {
                              id: data.data.id.toString(),
                            }),
                          )
                        }
                      >
                        <EditPenIcon />
                      </IconButton>
                    )}
                  </Box>
                  <Box pb="2rem" px="3rem">
                    <Box mb="2rem">
                      <Typography variant="h3" color="textSecondary">
                        General Information
                      </Typography>
                    </Box>
                    <Grid container spacing={2}>
                      <Grid
                        item
                        container
                        xs={6}
                        spacing={3}
                        style={{
                          alignContent: 'flex-start',
                        }}
                      >
                        <Grid item xs={6}>
                          <Typography>Vendor Type</Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography color="textSecondary">{convertEmptyField(data.data.vendorType)}</Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography>EIN</Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography color="textSecondary">{convertEmptyField(data.data.ein)}</Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography>E-mail</Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography color="textSecondary">{convertEmptyField(data.data.email)}</Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography>Phone</Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography color="textSecondary">{convertEmptyField(data.data.phone)}</Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography>Comments</Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography color="textSecondary">{convertEmptyField(data.data.comments)}</Typography>
                        </Grid>
                      </Grid>
                      <Grid item container xs={6} spacing={3}>
                        <UserPageInfo userPage={data.data.userPage} nameOnCheck={data.data.nameOnCheck} />
                      </Grid>
                    </Grid>
                  </Box>
                </Paper>
              </Grid>
              <Grid item xs={6}>
                <Paper elevation={0}>
                  <BusinessInfo
                    street={data.data.street}
                    businessName={data.data.businessName}
                    city={data.data.city}
                    state={data.data.state}
                    federalTaxId={data.data.federalTaxId}
                    zipCode={data.data.zipCode}
                  />
                </Paper>
              </Grid>
            </Grid>
          </Box>
        </>
      )}
    </Box>
  )
}
export default InfoVendorContainer
