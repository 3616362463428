import { IState } from 'typescript/interfaces/companies'
import { ExportType } from 'typescript/enums/export'
import { IUserPage } from 'typescript/interfaces/users'

export interface IVendorInitValues {
  ein: string
  vendorType: string
  comments: string
  phone: string
  email: string
  nameOnCheck: string
  userPage: IUserPage
  businessName: string
  city: string
  street: string
  zipCode: string
  federalTaxId: string
  stateId: number | ''
}

export enum SortVendorFields {
  id = 'Id',
  name = 'Name',
  ein = 'Ein',
  phone = 'Phone',
  address = 'Address',
  vendorType = 'VendorType',
  startingBalance = 'StartingBalance',
  availableBalance = 'AvailableBalance',
  lastTransactions = 'LastTransactions',
}

export interface IVendorEntity {
  id: number
  name: string
  ein: string
  vendorType: string
  comments: string
  phone: string
  email: string
  nameOnCheck: string
  userPage: Required<IUserPage>
  businessName: string
  city: string
  street: string
  zipCode: string
  federalTaxId: string
  state: IState
}

export interface IVendorListItem {
  id: number
  name: string
  ein: string
  vendorType: string
  comments: string
  businessName: string
  lastTransactions: string
  phone: string
  federalTaxId: string
  street: string
  city: string
  zipCode: string
  state: IState
  userPage: IUserPage
}

export interface IVendorListLightItem {
  id: number
  name: string
}

export interface IVendorExportParams {
  ExportType: ExportType
}
